import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    // path: '/home',
    name: 'Home',
    component: Home
  },
  {
    // path: '/',
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    // path: '/',
    path: '/lianxi',
    name: 'lianxi',
    component: () => import('../views/lianxi.vue')
  },
  {
    // path: '/',
    path: '/jiaoxue',
    name: 'jiaoxue',
    component: () => import('../views/jiaoxue.vue')
  },
  {
    // path: '/',
    path: '/jiazhang',
    name: 'jiazhang',
    component: () => import('../views/jiazhang.vue')
  },
  {
    // path: '/',
    path: '/yuanqu',
    name: 'yuanqu',
    component: () => import('../views/yuanqu.vue')
  },
  {
    // path: '/',
    path: '/xinwen',
    name: 'xinwen',
    component: () => import('../views/xinwen.vue')
  },
  {
    // path: '/',
    path: '/shizi',
    name: 'shizi',
    component: () => import('../views/shizi.vue')
  },
  {
    // path: '/',
    path: '/xwDetail',
    name: 'xwDetail',
    component: () => import('../views/xwDetail.vue')
  }, {
    // path: '/',
    path: '/kecheng',
    name: 'kecheng',
    component: () => import('../views/kecheng.vue')
  }, {
    // path: '/',
    path: '/huodong',
    name: 'huodong',
    component: () => import('../views/huodong.vue')
  },
  // xwLine
  {
    // path: '/',
    path: '/xwLine',
    name: 'xwLine',
    component: () => import('../views/xwLine.vue')
  },
  {
    // path: '/',
    path: '/channelOne',
    name: 'channelOne',
    component: () => import('../components/channel/channelOne.vue')
  },
  {
    // path: '/',
    path: '/channelTwo',
    name: 'channelTwo',
    component: () => import('../components/channel/channelTwo.vue')
  },
  {
    // path: '/',
    path: '/channelThree',
    name: 'channelThree',
    component: () => import('../components/channel/channelThree.vue')
  },
  {
    // path: '/',
    path: '/channelFour',
    name: 'channelFour',
    component: () => import('../components/channel/channelFour.vue')
  },

  {
    // path: '/',
    path: '/recruit',
    name: 'recruit',
    component: () => import('../views/recruit.vue')
  },

]

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  routes
})

export default router