<template>
  <div class="home" id="home">
    <headerNav></headerNav>
    <div class="screen1920">
      <el-carousel :interval="5000" height="750px">
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img :src="item.img" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="main">
        <div class="aboutUs">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">关于我们</span>
              <span class="leBtmSpan">ABOUT US</span>
            </div>
            <div class="rightContent" @click="toAbout">MORE+</div>
          </div>
          <div class="mainBtm">
            <div class="aboutLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/guoqi.png"
                alt=""
                class="imgOne"
              />
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huati.png"
                alt=""
                class="imgRightOne"
              />
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/chengbao.png"
                alt=""
                class="imgRightTwo"
              />
            </div>
            <div class="aboutRight">
              <div class="abRiTitle">燕京雍阳：为孩子的未来扬帆起航</div>
              <p>
                在童年这片美丽的土地上，有一个特别的地方——燕京雍阳。我们是一所不仅教育，更是关爱的幼儿园，因为深知，每个孩子都是独一无二的宝藏。
              </p>
            </div>
          </div>
        </div>

        <div class="development">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">发展历程</span>
              <span class="leBtmSpan">DEVELOPMENT PATH</span>
            </div>
            <div class="rightContent" @click="toAbout">MORE+</div>
          </div>
          <div class="developBox">
            <div class="development">
              <div class="developBox">
                <div class="deveItem">
                  <div class="deveTop">初创时期</div>
                  <div class="lineYellow"></div>
                  <img
                    src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
                    class="devetopImg huanImg"
                    alt=""
                  />
                  <div class="deveBtmText">
                    2020年，怀着对孩子未来的热爱和责任感，筹办了燕京雍阳幼儿园。初衷是为孩子们打造一个多元文化融合、注重全面发展的教育环境，满足每个孩子的独特需求。
                  </div>
                </div>
                <div class="deveItem">
                  <div class="devetopText">
                    2021年，燕京雍阳逐渐壮大，致力于培养出色的教育团队、精心打磨教学课程，以及创新的教学方
                    法，以确保教育达到最高标准。
                  </div>
                  <img
                    src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/lv.png"
                    class="devetopImg"
                    alt=""
                  />
                  <div class="lineGreen"></div>
                  <div class="deveBtm">不断发展</div>
                </div>
                <div class="deveItem">
                  <div class="deveTop">教育创新</div>
                  <div class="lineYellow"></div>
                  <img
                    src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
                    class="devetopImg huanImg"
                    alt=""
                  />
                  <div class="deveBtmText">
                    2021年9月，我们迈出了新的一步，正式开园招生。燕京雍阳幼儿园逐渐形成了独特的教育理念和文化氛围，注重培养孩子们的创造力、探索问题和解决问题的能力，同时也更关注孩子们的社交能力和情感发展。
                  </div>
                </div>
                <div class="deveItem">
                  <div class="devetopText">
                    如今，燕京雍阳拥有一支充满激情和专业性的教育团队。我们的教师都接受过专业培训，具备丰富的教育背景和经验，致力于为每个孩子提供最佳的教育。
                  </div>
                  <img
                    src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/lv.png"
                    class="devetopImg"
                    alt=""
                  />
                  <div class="lineGreen"></div>
                  <div class="deveBtm">专业团队</div>
                </div>
                <div class="deveItem">
                  <div class="deveTop">未来展望</div>
                  <div class="lineYellow"></div>
                  <img
                    src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
                    class="devetopImg huanImg"
                    alt=""
                  />
                  <div class="deveBtmText">
                    燕京雍阳将继续努力，不断创新。为孩子提供更多机会和更好的教育。我们坚信，每个孩子都有无限的潜力，而我们的使命就是帮助孩子充分发挥这些潜力，扬帆起航，创造美好的未来。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="teacherBox">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">教学理念</span>
              <span class="leBtmSpan">TEACHING STAFF</span>
            </div>
            <div class="rightContent" @click="toJiaoxue">MORE+</div>
          </div>
          <div class="teacherMain">
            <div class="teacherLeft">
              <img :src="teacher[activeIndex].img" alt="" />
              <span>{{ teacher[activeIndex].text }}</span>
              <div class="teacherBg"></div>
            </div>
            <div class="teacherRight">
              <div
                :class="
                  index == activeIndex
                    ? 'teacherItem teacherItemActive'
                    : 'teacherItem'
                "
                v-for="(item, index) in teacher"
                :key="index"
                v-on:mouseenter="toCheck(index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>

        <div class="newBox">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">新闻</span>
              <span class="leBtmSpan">NEWS</span>
            </div>
            <div class="rightContent" @click="toXinwen()">MORE+</div>
          </div>
          <el-carousel indicator-position="none" height="600px">
            <el-carousel-item
              v-for="item in news"
              :key="item"
              style="height: 600px"
            >
              <img
                :src="item.remark1"
                alt=""
                style="height: 490px"
                @click="toXinwen(item.id)"
              />
              <div class="newsContent">
                <div class="newsLeft">{{ item.title }}</div>
                <div class="newsRight">{{ item.createTime }}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="teamBox">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">优秀师资团队介绍</span>
              <span class="leBtmSpan">EXCELLENT TEACHING STAFF</span>
            </div>
            <div class="rightContent" @click="toShizi">MORE+</div>
          </div>
          <div class="teamMain">
            <div class="teamLeft">
              <img :src="team[0].img" alt="" />
            </div>
            <div class="teamRight">
              <div class="titleTeam">{{ team[0].title }}</div>
              <div class="ql-snow">
                <div class="ql-editor" v-html="team[0].text"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="courseBox">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">课程介绍</span>
              <span class="leBtmSpan">COURSE INTRODUCTION</span>
            </div>
            <div class="rightContent" @click="toKecheng">MORE+</div>
          </div>
          <div class="courseMain">
            <div class="leftCourse">
              <img :src="course[0].img" alt="" />
            </div>
            <div class="rightCouse">
              <div
                v-for="(item, index) in course"
                :key="index"
                @click="toKechengKc(item.title)"
              >
                <div
                  :class="
                    index == 0
                      ? 'courseItem0'
                      : index == 5
                      ? 'courseItem5'
                      : 'courseItem'
                  "
                >
                  <div class="courseText">{{ item.title }}</div>
                  <div style="overflow: hidden">
                    <img
                      :src="item.img"
                      alt=""
                      :class="index == 5 ? 'courseItemImg5' : 'courseItemImg'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="teBox">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">特色课程</span>
              <span class="leBtmSpan">FEATURED PROGRAMS</span>
            </div>
            <div class="rightContent" @click="toKecheng">MORE+</div>
          </div>
          <div class="teseMain">
            <div
              class="teseItem"
              v-for="(item, index) in tese"
              :key="index"
              @click="toKechengWz(item.title)"
            >
              <div style="overflow: hidden; border-radius: 20px">
                <img :src="item.img" alt="" />
              </div>
              <div class="teseName">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="zsBox">
        <div class="zsMain">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">招生信息</span>
              <span class="leBtmSpan">ADMISSIONS INFORMATION</span>
            </div>
            <div></div>
          </div>
        </div>

        <div class="zsAge">
          <div class="zsMain zsMain1">
            <div class="zsAgeTitle">
              <span class="beforeLine"></span>
              招生年龄段
              <span class="afterLine"></span>
            </div>
          </div>
          <div class="ageFanwei">2.5-6岁</div>
        </div>

        <div class="zsLiucheng">
          <div class="zsMain zsMain1">
            <div class="zsLiuchengTitle">
              <span class="beforeLine1"></span>
              报名流程
              <span class="afterLine1"></span>
            </div>
          </div>

          <div class="lcBox">
            <div class="one yellowBox">1.电话或微信咨询，预约参观</div>
            <div class="yellowLine"></div>
            <div class="two greenBox">2.参观园所，了解情况</div>
            <div class="yellowLine"></div>
            <div class="three yellowBox">3.预约园长面谈</div>
            <div class="four greenBox" style="margin-top: 40px">
              4.办理入园手续
            </div>
            <div class="yellowLine"></div>
            <div class="five yellowBox">5.提交入园资料并与老师一对一沟通</div>
            <div class="yellowLine"></div>
            <div class="six greenBox">6.正式入园</div>
          </div>
        </div>

        <div class="zsAge zsAge1">
          <div class="zsMain zsMain1">
            <div class="zsAgeTitle">
              <span class="beforeLine"></span>
              费用结构
              <span class="afterLine"></span>
            </div>
          </div>
          <div class="ageFanwei">保育教育费+餐费</div>
        </div>

        <div class="zsLiucheng">
          <div class="zsMain zsMain1">
            <div class="zsLiuchengTitle">
              <span class="beforeLine1"></span>
              报名信息
              <span class="afterLine1"></span>
            </div>
          </div>

          <div class="baoming">
            <div class="demo-input-suffix">
              <span class="titleLabel"
                >学生姓名
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/%E5%BF%85%E5%A1%AB.png"
                  alt=""
                />
              </span>
              <el-input placeholder="请输入学生姓名" v-model="nameStu">
              </el-input>
            </div>
            <div class="demo-input-suffix">
              <span class="titleLabel"
                >学生年龄
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/%E5%BF%85%E5%A1%AB.png"
                  alt=""
                />
              </span>
              <el-input
                placeholder="请输入学生年龄"
                v-model="sexStu"
              ></el-input>
              <!-- <el-select v-model="sexStu" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
            </div>
            <div class="demo-input-suffix">
              <span class="titleLabel"
                >联系方式
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/%E5%BF%85%E5%A1%AB.png"
                  alt=""
                />
              </span>
              <el-input placeholder="请输入联系方式" v-model="connectStu">
              </el-input>
            </div>
          </div>

          <div class="submitBtn" @click="toSubmit">提交信息</div>

          <footerBtm></footerBtm>
        </div>
      </div>
    </div>
    <div class="screen750">
      <div class="banner750">
        <el-carousel :interval="5000" height="146px" :arrow="alwaysHome">
          <el-carousel-item v-for="item in bannerList" :key="item">
            <img :src="item.img" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="main">
        <div class="aboutUs" @click="toAbout">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">关于我们</span>
              <span class="leBtmSpan">ABOUT US</span>
            </div>
            <div class="rightContent">MORE+</div>
          </div>
          <div class="mainBtm">
            <div class="aboutLeft1">
              <div class="leftOneAbout">
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/guoqi.png"
                  alt=""
                  class="imgOne1"
                />
              </div>
              <div class="leftTwoAbout">
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huati.png"
                  alt=""
                  class="imgRightOne1"
                />
                <img
                  src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/chengbao.png"
                  alt=""
                  class="imgRightTwo1"
                />
              </div>
            </div>
            <div class="aboutRight">
              <div class="abRiTitle">燕京雍阳：为孩子的未来扬帆起航</div>
              <p>
                在童年这片美丽的土地上，有一个特别的地方——燕京雍阳。我们是一所不仅教育，更是关爱的幼儿园，因为深知，每个孩子都是独一无二的宝藏。
              </p>
            </div>
          </div>
        </div>
        <div class="development">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">发展历程</span>
              <span class="leBtmSpan">DEVELOPMENT PATH</span>
            </div>
            <div class="rightContent" @click="toAbout">MORE+</div>
          </div>
          <div class="developBox">
            <div class="development">
              <div class="developBox">
                <div class="deveItem" @click="toSwitchTitle(1)">
                  <div class="deveTop">初创</div>
                  <div class="lineYellow"></div>
                  <img
                    src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
                    class="devetopImg huanImg"
                    alt=""
                    v-if="deveIndex == 1"
                  />
                </div>
                <div class="deveItem" @click="toSwitchTitle(2)">
                  <div class="deveTop">发展</div>
                  <div class="lineGreen"></div>
                  <img
                    src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/lv.png"
                    class="devetopImg huanImg"
                    alt=""
                    v-if="deveIndex == 2"
                  />
                </div>
                <div class="deveItem" @click="toSwitchTitle(3)">
                  <div class="deveTop">创新</div>
                  <div class="lineYellow"></div>
                  <img
                    src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
                    class="devetopImg huanImg"
                    alt=""
                    v-if="deveIndex == 3"
                  />
                </div>
                <div class="deveItem" @click="toSwitchTitle(4)">
                  <div class="deveTop">专业</div>
                  <div class="lineGreen"></div>
                  <img
                    src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/lv.png"
                    class="devetopImg huanImg"
                    alt=""
                    v-if="deveIndex == 4"
                  />
                </div>
                <div class="deveItem" @click="toSwitchTitle(5)">
                  <div class="deveTop">未来</div>
                  <div class="lineYellow"></div>
                  <img
                    src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huang.png"
                    class="devetopImg huanImg"
                    alt=""
                    v-if="deveIndex == 5"
                  />
                </div>
              </div>
              <div class="deveBtmText" v-if="deveIndex == 1" @click="toAbout">
                <div class="titleDeve">初创时期</div>
                <p>
                  2020年，怀着对孩子未来的热爱和责任感，筹办了燕京雍阳幼儿园。初衷是为孩子们打造一个多元文化融合、注重全面发展的教育环境，满足每个孩子的独特需求。
                </p>
              </div>
              <div class="deveBtmText" v-if="deveIndex == 2" @click="toAbout">
                <div class="titleDeve">不断发展</div>
                <p>
                  2021年，燕京雍阳逐渐壮大，致力于培养出色的教育团队、精心打磨教学课程，以及创新的教学方
                  法，以确保教育达到最高标准。
                </p>
              </div>
              <div class="deveBtmText" v-if="deveIndex == 3" @click="toAbout">
                <div class="titleDeve">教育创新</div>
                <p>
                  2021年9月，我们迈出了新的一步，正式开园招生。燕京雍阳幼儿园逐渐形成了独特的教育理念和文化氛围，注重培养孩子们的创造力、探索问题和解决问题的能力，同时也更关注孩子们的社交能力和情感发展。
                </p>
              </div>
              <div class="deveBtmText" v-if="deveIndex == 4" @click="toAbout">
                <div class="titleDeve">专业团队</div>
                <p>
                  如今，燕京雍阳拥有一支充满激情和专业性的教育团队。我们的教师都接受过专业培训，具备丰富的教育背景和经验，致力于为每个孩子提供最佳的教育。
                </p>
              </div>
              <div class="deveBtmText" v-if="deveIndex == 5" @click="toAbout">
                <div class="titleDeve">未来展望</div>
                <p>
                  燕京雍阳将继续努力，不断创新。为孩子提供更多机会和更好的教育。我们坚信，每个孩子都有无限的潜力，而我们的使命就是帮助孩子充分发挥这些潜力，扬帆起航，创造美好的未来。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="teacherBox">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">教学理念</span>
              <span class="leBtmSpan">TEACHING STAFF</span>
            </div>
            <div class="rightContent" @click="toJiaoxue">MORE+</div>
          </div>
          <div class="teacherMain">
            <div class="teacherLeft" @click.stop="toJiaoxue">
              <img :src="teacher[activeIndex].img" alt="" />
              <span>{{ teacher[activeIndex].text }}</span>
              <div class="teacherBg"></div>
            </div>
            <div class="teacherRight">
              <div
                :class="
                  index == activeIndex
                    ? 'teacherItem teacherItemActive'
                    : 'teacherItem'
                "
                v-for="(item, index) in teacher"
                :key="index"
                @click.stop="toCheck(index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>

        <div class="newBox">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">新闻</span>
              <span class="leBtmSpan">NEWS</span>
            </div>
            <div class="rightContent" @click="toXinwen()">MORE+</div>
          </div>

          <el-carousel indicator-position="none" height="134px" arrow="always">
            <el-carousel-item
              v-for="item in news"
              :key="item"
              style="height: 184px"
            >
              <img
                :src="item.remark1"
                alt=""
                style="height: 134px"
                @click="toXinwen(item.id)"
              />
              <div class="newsContent">
                <div class="newsLeft">{{ item.title }}</div>
                <div class="newsRight">{{ item.createTime }}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="teamBox" @click="toShizi">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">师资团队</span>
              <span class="leBtmSpan">TEACHING STAFF</span>
            </div>
            <div class="rightContent">MORE+</div>
          </div>
          <div class="teamMain">
            <div class="teamLeft">
              <img :src="team[0].img" alt="" />
            </div>
            <div class="teamRight">
              <div class="titleTeam">{{ team[0].title }}</div>
              <p>
                25 年幼教工作经验 <br />
                IB教学经验十年 <br />
                多次获得官方认证证书
              </p>
            </div>
          </div>
        </div>

        <div class="courseBox" @click="toKecheng">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">课程介绍</span>
              <span class="leBtmSpan">COURSE INTRODUCTION</span>
            </div>
            <div class="rightContent">MORE+</div>
          </div>
          <div class="courseMain">
            <div class="leftCourse">
              <img :src="course[0].img" alt="" />
            </div>
            <div class="rightCouse">
              <div v-for="(item, index) in course" :key="index">
                <div
                  :class="
                    index == 0
                      ? 'courseItem0'
                      : index == 5
                      ? 'courseItem5'
                      : 'courseItem'
                  "
                >
                  <div class="courseText">{{ item.title }}</div>
                  <div style="overflow: hidden">
                    <img
                      :src="item.img"
                      alt=""
                      :class="index == 5 ? 'courseItemImg5' : 'courseItemImg'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="teBox" @click="toKecheng">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">特色课程</span>
              <span class="leBtmSpan">FEATURED PROGRAMS</span>
            </div>
            <div class="rightContent">MORE+</div>
          </div>
          <div class="teseMain teseMain1">
            <el-carousel
              indicator-position="none"
              height="184px"
              arrow="always"
            >
              <el-carousel-item
                v-for="item in tese"
                :key="item"
                style="height: 184px"
                @click="toKechengWz(item.title)"
              >
                <img :src="item.img" alt="" style="height: 184px" />
                <div class="teseTitle">{{ item.title }}</div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="zsBox">
        <div class="zsMain">
          <div class="mainTop">
            <div class="leftContent">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
              <span class="leTopSpan">招生信息</span>
              <span class="leBtmSpan">ADMISSIONS INFORMATION</span>
            </div>
            <div></div>
          </div>
        </div>

        <div class="zsAge">
          <div class="zsMain zsMain1">
            <div class="zsAgeTitle">
              <span class="beforeLine"></span>
              招生年龄段
              <span class="afterLine"></span>
            </div>
          </div>
          <div class="ageFanwei">2.5-6岁</div>
        </div>

        <div class="zsLiucheng">
          <div class="zsMain zsMain1">
            <div class="zsLiuchengTitle">
              <span class="beforeLine1"></span>
              报名流程
              <span class="afterLine1"></span>
            </div>
          </div>

          <div class="screen750Box">
            <div class="leftScreen">
              <div class="leftOne">
                <p>1.电话或微信咨询，预约参观</p>
              </div>
              <div class="leftTwo">2.参观园所，了解情况</div>
              <div class="leftThree">3.预约园长面谈</div>
            </div>
            <div class="rightScreen">
              <div class="rightOne">4.办理入园手续</div>
              <div class="rightTwo">
                <p>5.提交入园资料并 与老师一对一沟通</p>
              </div>
              <div class="rightThree">6.正式入园</div>
            </div>
          </div>
        </div>

        <div class="zsAge zsAge1">
          <div class="zsMain zsMain1">
            <div class="zsAgeTitle">
              <span class="beforeLine"></span>
              费用结构
              <span class="afterLine"></span>
            </div>
          </div>
          <div class="ageFanwei">保育教育费+餐费</div>
        </div>

        <div class="zsLiucheng">
          <div class="zsMain zsMain1">
            <div class="zsLiuchengTitle">
              <span class="beforeLine1"></span>
              报名信息
              <span class="afterLine1"></span>
            </div>
          </div>

          <div class="baoming">
            <div class="demo-input-suffix">
              <span class="titleLabel">学生姓名 </span>
              <el-input placeholder="请输入学生姓名" v-model="nameStu">
              </el-input>
            </div>
            <div class="demo-input-suffix">
              <span class="titleLabel">学生年龄 </span>
              <el-input placeholder="请输入学生年龄" v-model="sexStu">
              </el-input>
              <!-- <el-select v-model="sexStu" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
            </div>
            <div class="demo-input-suffix">
              <span class="titleLabel">联系方式 </span>
              <el-input placeholder="请输入联系方式" v-model="connectStu">
              </el-input>
            </div>
          </div>

          <div class="submitBtn" @click="toSubmit">提交信息</div>

          <footerBtm></footerBtm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";

export default {
  name: "Home",
  components: {
    headerNav,
    footerBtm,
  },
  data() {
    return {
      alwaysHome: false,
      options: [
        {
          value: "男",
          label: "男",
        },
        {
          value: "女",
          label: "女",
          disabled: true,
        },
      ],
      deveIndex: 1,
      nameStu: "",
      sexStu: "",
      connectStu: "",
      bannerList: [],
      // aboutUs: {},
      development: {},
      // deveList: [],
      teacher: [],
      activeIndex: 0,
      course: [],
      news: [],
      team: {},
      tese: [],
      juli: 0,
    };
  },
  updated() {
    let num = localStorage.getItem("yyNum");
    if (num == 0) {
      window.scrollTo(0, 6900);
      if (window.scrollY >= 6900) {
        localStorage.setItem("yyNum", 1);
      }
    }
  },
  watch: {
    "$store.state.count": function () {
      window.scrollTo(0, 6900);
      // if (this.$store.state.count == 0) {
      //   this.$store.commit("increment");
      // } else {
      //   this.$store.commit("subtraction");
      // }
    },
  },
  mounted() {
    // allPage
    console.log(this);
    this.api.allPage({ name: "首页" }).then((res) => {});
    if (this.$route.query.juli) {
      this.juli = this.$route.query.juli;
    }
    this.api.banner({ remark1: 1 }).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].img = JSON.parse(res.data[i].img);
      }
      if (res.data.length > 0) {
        this.alwaysHome = true;
      }
      this.bannerList = res.data;
    });
    // 首页
    this.api.home({}).then((res) => {
      // 关于我们
      // this.aboutUs = res.data[0][0];
      // 教学理念
      this.teacher = res.data[0];
      // 新闻
      for (var i = 0; i < res.data[1].length; i++) {
        res.data[1][i].remark1 = JSON.parse(res.data[1][i].remark1);
      }
      this.news = res.data[1];
      res.data[2][0].img = JSON.parse(res.data[2][0].img);
      // 师资团队
      this.team = res.data[2];

      // 课程介绍
      this.course = res.data[3];
      // 特色课程
      this.tese = res.data[4];
      console.log(this.tese);
    });
  },
  methods: {
    toSwitchTitle(e) {
      this.deveIndex = e;
    },

    toAbout() {
      // this.$router.push({path:'路径')};
      this.$router.push({ path: "/about" });
    },
    toJiaoxue() {
      this.$router.push({ path: "/jiaoxue" });
    },
    toXinwen(e) {
      if (e) {
        this.$router.push(`/xwDetail?id=${e}`);
      } else {
        this.$router.push({ path: "/xinwen" });
      }
    },
    toShizi() {
      this.$router.push({ path: "/shizi" });
    },
    toKechengKc(e) {
      this.$router.push({ path: "/kecheng", query: { title: e, type: "one" } });
    },
    toKecheng(e) {
      this.$router.push({ path: "/kecheng" });
    },
    // 特色课程跳转
    toKechengWz(e) {
      this.$router.push({ path: "/kecheng", query: { title: e, type: "two" } });
    },
    toCheck(e) {
      this.activeIndex = e;
    },

    toSubmit() {
      if (this.nameStu == "") {
        this.$alert("您还未填写“姓名”", {
          confirmButtonText: "确定",
        });
      }
      if (this.sexStu == "") {
        this.$alert("您还未填写“年龄”", "", {
          confirmButtonText: "确定",
        });
      }
      if (this.connectStu == "") {
        this.$alert("您还未填写“联系方式”", "", {
          confirmButtonText: "确定",
        });
      }
      if (this.nameStu != "" && this.sexStu != "" && this.connectStu != "") {
        this.api
          .sumitHome({
            name: this.nameStu,
            sex: this.sexStu,
            phone: this.connectStu,
            type: 0,
            owner: "admin",
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.$alert("报名成功，请等待老师联系", "", {
                confirmButtonText: "确定",
                callback: (action) => {
                  (this.nameStu = ""),
                    (this.sexStu = ""),
                    (this.connectStu = "");
                },
              });
            }
          });
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1920px) {
  .screen1920 {
    display: block;
  }
  .screen750 {
    display: none;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 750px;
  }
  .newBox .el-carousel,
  .newBox .el-carousel__item,
  .newBox .el-carousel img {
    height: 600px;
    border: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .main {
    width: 1280px;
    /* padding-top: 80px; */
    margin: 0 auto;
  }
  .aboutUs {
    width: 1280px;
  }
  .mainTop {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 30px;
    /* background: pink; */
  }

  .leftContent {
    width: 500px;
    position: relative;
    /* background: yellow; */
  }
  .leftContent img {
    width: 80px;
    height: 80px;
  }

  .leTopSpan {
    font-size: 40px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    position: absolute;
    top: 0;
    left: 100px;
  }

  .leBtmSpan {
    position: absolute;
    bottom: 0;
    left: 100px;
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
  }

  .rightContent {
    width: 106px;
    height: 48px;
    background: #ffe500;
    border-radius: 8px 8px 8px 8px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
  }
  .rightContent:hover {
    background: #25ae85;
    color: white;
  }

  .mainBtm {
    width: 100%;
    height: 365px;
    display: flex;
    justify-content: space-between;
  }

  .aboutLeft {
    width: 595px;
    height: 365px;
    position: relative;
  }

  .imgOne {
    width: 285px;
    height: 365px;
  }
  .imgRightOne {
    width: 285px;
    height: 164px;
    position: absolute;
    top: 0;
    left: 305px;
  }
  .imgRightTwo {
    width: 285px;
    height: 164px;
    position: absolute;
    bottom: 0;
    left: 305px;
  }

  .aboutRight {
    width: 640px;
    height: 365px;
    /* background: purple; */
  }
  .abRiTitle {
    font-size: 40px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    margin-top: 60px;
    margin-bottom: 66px;
  }
  .aboutRight p {
    width: 646px;
    height: 144px;
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 48px;
  }
  .developBox {
    width: 100%;
    display: flex;
  }

  .deveItem {
    width: 256px;
  }

  .deveTop {
    font-size: 28px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    text-align: center;
    margin-top: 154px;
  }

  .devetopText,
  .deveBtmText {
    width: 100%;
    height: 128px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    margin: 20px 0;
  }

  .devetopImg {
    width: 28px;
    height: 28px;
    margin: 0 114px 20px 114px;
  }
  .huanImg {
    margin: 20px 114px;
  }

  .lineYellow {
    width: 100%;
    height: 20px;
    background: #ffe500;
    margin-top: 30px;
  }
  .lineGreen {
    width: 100%;
    height: 20px;
    background: #25ae85;
  }

  .deveBtm {
    font-size: 28px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    text-align: center;
    margin-top: 30px;
  }

  .teacherBox {
    width: 100%;
  }
  .teacherMain {
    width: 100%;
    height: 600px;
    margin-top: 70px;
    display: flex;
  }

  .teacherLeft {
    width: 800px;
    height: 600px;
    position: relative;
  }
  .teacherBg {
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    border-bottom-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .teacherLeft img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .teacherLeft span {
    position: absolute;
    left: 40px;
    bottom: 25px;
    display: block;
    width: 720px;
    height: 144px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 24px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    z-index: 44;
  }

  .teacherRight {
    width: 480px;
    height: 600px;
  }

  .teacherItem {
    width: 480px;
    height: 200px;
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    background: white;
    text-align: center;
    line-height: 200px;
    cursor: pointer;
  }
  .teacherItemActive {
    background: #25ae85;
    font-size: 40px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .teacherItem:first-child {
    border-top-right-radius: 20px;
  }
  .teacherItem:last-child {
    border-bottom-right-radius: 20px;
  }

  .newBox {
    width: 100%;
  }

  .newsContent {
    width: 100%;
    height: 110px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffe500;
    display: flex;
    justify-content: space-between;
  }

  .newsLeft {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    line-height: 100px;
    margin-left: 40px;
  }
  .newsRight {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    line-height: 100px;
    margin-right: 40px;
  }

  .teamMain {
    width: 100%;
    display: flex;
  }
  .teamLeft {
    width: 430px;
    height: 570px;
  }
  .teamLeft img {
    width: 100%;
    height: 100%;
  }

  .teamRight {
    width: 794px;
    margin-left: 56px;
  }
  .titleTeam {
    font-size: 36px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    margin-bottom: 34px;
    margin-left: 25px;
  }

  .courseBox {
    width: 100%;
  }
  .courseMain {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .leftCourse {
    width: 700px;
    height: 700px;
  }
  .leftCourse img {
    width: 100%;
    height: 100%;
  }

  .rightCouse {
    width: 493px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .courseItem {
    width: 240px;
    height: 296px;
    background: #feca0a;
    border-radius: 20px;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .courseItem0 {
    display: none;
  }
  .courseItem5 {
    width: 493px;
    height: 177px;
    border-radius: 20px;
    background: #feca0a;
    cursor: pointer;
  }

  .courseItemImg {
    width: 240px;
    height: 240px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: inline-block;
    transition: transform 0.3s ease;
  }
  .courseItemImg:hover {
    transform: scale(1.2);
  }
  .courseItemImg5 {
    width: 493px;
    height: 121px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: transform 0.3s ease;
  }
  .courseItemImg5:hover {
    transform: scale(1.2);
  }
  .courseText {
    width: 100%;
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    height: 56px;
    line-height: 56px;
    /* background: red; */
  }

  .teseMain {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .teseItem {
    width: 400px;
    height: 290px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .teseItem img {
    width: 400px;
    height: 245px;
    transition: transform 0.3s ease;
  }
  .teseItem img:hover {
    transform: scale(1.2);
  }

  .teseName {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    margin-top: 10px;
  }

  .zsBox {
    width: 100%;
    height: 800px;
    /* background: yellow; */
  }
  .zsMain {
    width: 1280px;
    margin: 0 auto;
  }
  .zsMain1 {
    position: relative;
  }
  .zsAge {
    width: 100%;
    height: 196px;
    padding-top: 40px;
    background: #25ae85;
  }

  .zsAge1 {
    margin-top: 200px;
  }

  .zsLiucheng {
    width: 100%;
    height: 196px;
    padding-top: 40px;
  }
  .zsAgeTitle {
    font-size: 40px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
  .zsLiuchengTitle {
    font-size: 40px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
  }

  .beforeLine {
    width: 400px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #ffffff;
    display: inline-block;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
  }
  .afterLine {
    width: 400px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #ffffff;
    display: inline-block;
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
  }

  .beforeLine1 {
    width: 400px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #000000;
    display: inline-block;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
  }
  .afterLine1 {
    width: 400px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #000000;
    display: inline-block;
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
  }

  .ageFanwei {
    font-size: 32px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-top: 60px;
  }

  .lcBox {
    width: 1280px;
    margin: 40px auto;
  }

  .yellowBox {
    background: #ffe500;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    font-size: 32px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    display: inline-block;
    height: 96px;
    line-height: 96px;
  }
  .greenBox {
    background: #25ae85;
    border-radius: 20px 20px 20px 20px;
    font-size: 32px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    height: 96px;
    line-height: 96px;
  }

  .yellowLine {
    width: 66px;
    height: 0px;
    opacity: 1;
    border: 6px solid #ffe500;
    display: inline-block;
  }
  .one {
    width: 446px;
  }
  .two {
    width: 378px;
  }

  .three {
    width: 300px;
  }
  .four {
    width: 300px;
  }
  .five {
    width: 588px;
  }
  .six {
    width: 236px;
  }

  .baoming {
    width: 1280px;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
  }
  .demo-input-suffix {
    width: 400px;
  }

  div /deep/.el-input {
    position: relative;
    font-size: 14px;
    width: 400px;
  }
  div /deep/.el-input__inner {
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    border-radius: 100px;
  }

  .titleLabel {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    display: inline-block;
    padding-bottom: 20px;
    position: relative;
  }
  .titleLabel img {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -20px;
    top: 5px;
  }

  .submitBtn {
    width: 600px;
    height: 74px;
    background: #ffe500;
    border-radius: 100px 100px 100px 100px;
    font-size: 26px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    line-height: 74px;
    margin: 60px auto;
    cursor: pointer;
    margin-bottom: 200px;
  }
  div /deep/ .el-carousel__arrow {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
@media screen and (max-width: 1680px) {
  
}
@media screen and (max-width: 1440px) {
  div /deep/ .el-carousel__container {
    height: 563px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
  .newBox img {
    border-radius: 20px;
  }
}
@media screen and (max-width: 1366px) {
  div /deep/ .el-carousel__container {
    height: 534px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  div /deep/ .newBox .el-carousel__container {
    height: 100% !important;
  }

  div /deep/ .newBox .el-carousel__container img {
    height: 349px !important;
  }
  .newBox .el-carousel,
  .newBox .el-carousel__item,
  .newBox .el-carousel img {
    width: 100%;
    height: 426px !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .newBox img {
    border-radius: 0px;
  }

  .newsContent {
    width: 100%;
    height: 78px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffe500;
    display: flex;
    justify-content: space-between;
  }

  .newsLeft {
    font-size: 17px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    line-height: 78px;
    margin-left: 40px;
  }
  .newsRight {
    font-size: 17px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    line-height: 78px;
    margin-right: 40px;
  }
  .main {
    width: 910px;
    padding-top: 30px;
    margin: 0 auto;
  }
  .mainTop {
    width: 100%;
    height: 57px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 30px;
  }
  .mainBtm {
    width: 100%;
    height: 305px;
    display: flex;
    justify-content: space-between;
  }
  .leTopSpan {
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    position: absolute;
    top: 0;
    left: 60px;
  }
  .leBtmSpan {
    position: absolute;
    bottom: 0;
    left: 60px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
  }
  .leftContent img {
    width: 57px;
    height: 57px;
  }
  .aboutUs {
    width: 910px;
  }

  .aboutLeft {
    width: 423px;
    height: 260px;
    position: relative;
  }

  .imgOne {
    width: 203px;
    height: 260px;
  }
  .imgRightOne {
    width: 203px;
    height: 117px;
    position: absolute;
    top: 0;
    left: 215px;
  }
  .imgRightTwo {
    width: 203px;
    height: 117px;
    position: absolute;
    bottom: 0;
    left: 215px;
  }

  .aboutRight {
    width: 460px;
    height: 260px;
    /* background: purple; */
  }
  .abRiTitle {
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    margin-top: 40px;
    margin-bottom: 44px;
  }
  .aboutRight p {
    width: 430px;
    height: 100px;
    font-size: 17px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 34px;
  }
  .deveItem {
    width: 182px;
  }
  .huanImg {
    margin: 20px 81px;
  }
  .devetopImg {
    width: 28px;
    height: 28px;
    margin: 0 81px 42px 81px;
  }
  .deveBtmText {
    width: 100%;
    height: 107px;
    font-size: 11px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    margin: 20px 0;
  }
  .devetopText,
  .deveBtmText {
    width: 100%;
    height: 107px;
    font-size: 11px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    margin: 20px 0;
  }

  .teacherMain {
    width: 100%;
    height: 400px;
    margin-top: 70px;
    display: flex;
  }
  .teacherLeft {
    width: 569px;
    height: 427px;
    position: relative;
  }
  .teacherLeft span {
    position: absolute;
    left: 40px;
    bottom: 25px;
    display: block;
    width: 500px;
    height: 100px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    z-index: 44;
  }
  .teacherBg {
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    border-bottom-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .teacherLeft img {
    width: 569px;
    height: 427px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .teacherRight {
    width: 342px;
    height: 427px;
  }

  .teacherItem {
    width: 342px;
    height: 142px;
    font-size: 17px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    background: white;
    text-align: center;
    line-height: 142px;
    cursor: pointer;
  }
  .teacherItemActive {
    background: #25ae85;
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }

  .teamLeft {
    width: 306px;
    height: 406px;
  }
  .teamRight {
    width: 565px;
    height: 408px;
    margin-left: 56px;
  }

  div /deep/li span {
    font-size: 16px !important;
  }
  .titleTeam {
    font-size: 26px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    margin-bottom: 34px;
    margin-left: 52px;
  }

  .newBox {
    width: 910px;
    margin: 0 auto;
    margin-top: 50px;
  }

  .leftCourse {
    width: 498px;
    height: 498px;
  }
  .leftCourse img {
    width: 100%;
    height: 100%;
  }
  .teBox {
    width: 910px;
    margin: 0 auto;
  }
  .teseItem {
    width: 285px;
    height: 204px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .teseItem img {
    width: 285px;
    height: 174px;
    transition: transform 0.3s ease;
  }
  .teseName {
    font-size: 17px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    margin-top: 10px;
  }
  .rightCouse {
    width: 351px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
  }

  .courseItem {
    width: 171px;
    height: 211px;
    background: #feca0a;
    border-radius: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .courseItem0 {
    display: none;
  }
  .courseItem5 {
    width: 351px;
    height: 126px;
    border-radius: 20px;
    background: #feca0a;
    cursor: pointer;
  }

  .courseItemImg {
    width: 171px;
    height: 171px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: inline-block;
    transition: transform 0.3s ease;
  }
  .courseItemImg:hover {
    transform: scale(1.2);
  }
  .courseItemImg5 {
    width: 351px;
    height: 86px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: transform 0.3s ease;
  }
  .courseItemImg5:hover {
    transform: scale(1.2);
  }
  .courseText {
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    height: 38px;
    line-height: 38px;
  }

  .zsMain {
    width: 910px;
    margin: 0 auto;
  }

  .zsAgeTitle {
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
  .beforeLine {
    width: 285px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #ffffff;
    display: inline-block;
    position: absolute;
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
  .afterLine {
    width: 285px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #ffffff;
    display: inline-block;
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
  .beforeLine1 {
    width: 285px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #000000;
    display: inline-block;
    position: absolute;
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
  .afterLine1 {
    width: 285px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #000000;
    display: inline-block;
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
  .zsLiuchengTitle {
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
  }
  .ageFanwei {
    font-size: 23px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-top: 60px;
  }
  .lcBox {
    width: 910px;
    margin: 40px auto;
  }

  .yellowBox {
    background: #ffe500;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    font-size: 23px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    display: inline-block;
    height: 68px;
    line-height: 68px;
  }
  .greenBox {
    background: #25ae85;
    border-radius: 20px 20px 20px 20px;
    font-size: 23px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    height: 68px;
    line-height: 68px;
  }

  .yellowLine {
    width: 55px;
    height: 0px;
    opacity: 1;
    border: 4px solid #ffe500;
    display: inline-block;
  }
  .one {
    width: 310px;
  }
  .two {
    width: 260px;
  }

  .three {
    width: 213px;
  }
  .four {
    width: 213px;
  }
  .five {
    width: 410px;
  }
  .six {
    width: 160px;
  }
  .baoming {
    width: 910px;
    margin: 0 auto;
    margin-top: 0px;
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
  }
  .demo-input-suffix {
    width: 290px;
  }
  div /deep/ .el-input {
    position: relative;
    font-size: 14px;
    width: 290px;
  }
  .titleLabel {
    font-size: 17px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    display: inline-block;
    padding-bottom: 20px;
    position: relative;
  }
  .submitBtn {
    width: 427px;
    height: 53px;
    background: #ffe500;
    border-radius: 100px 100px 100px 100px;
    font-size: 26px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    line-height: 53px;
    margin: 60px auto;
    margin-bottom: 60px;
    cursor: pointer;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 1280px) {
  div /deep/ .el-carousel__container {
    height: 500px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 500px;
  }
  .main {
    width: 860px;
    padding-top: 30px;
    margin: 0 auto;
  }
  .mainTop {
    width: 100%;
    height: 53px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .leTopSpan {
    font-size: 27px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    position: absolute;
    top: 0;
    left: 60px;
  }
  .leBtmSpan {
    position: absolute;
    bottom: 0;
    left: 60px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
  }
  .leftContent img {
    width: 53px;
    height: 53px;
  }
  .aboutUs {
    width: 860px;
  }

  .aboutLeft {
    width: 380px;
    height: 243px;
    position: relative;
  }

  .imgOne {
    width: 190px;
    height: 243px;
  }
  .imgRightOne {
    width: 190px;
    height: 109px;
    position: absolute;
    top: 0;
    left: 205px;
  }
  .imgRightTwo {
    width: 190px;
    height: 109px;
    position: absolute;
    bottom: 0;
    left: 205px;
  }

  .aboutRight {
    width: 430px;
    height: 243px;
    /* background: purple; */
  }
  .abRiTitle {
    font-size: 27px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    margin-top: 40px;
    margin-bottom: 44px;
  }
  .aboutRight p {
    width: 430px;
    height: 100px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 32px;
  }
  .deveItem {
    width: 171px;
  }
  .huanImg {
    margin: 20px 76px;
  }
  .devetopImg {
    width: 28px;
    height: 28px;
    margin: 0 76px 42px 76px;
  }
  .deveBtmText {
    width: 100%;
    height: 107px;
    font-size: 11px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    margin: 20px 0;
  }
  .devetopText,
  .deveBtmText {
    width: 100%;
    height: 107px;
    font-size: 11px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    margin: 20px 0;
  }

  .teacherMain {
    width: 100%;
    height: 400px;
    margin-top: 70px;
    display: flex;
  }
  .teacherLeft {
    width: 533px;
    height: 400px;
    position: relative;
  }
  .teacherLeft span {
    position: absolute;
    left: 40px;
    bottom: 25px;
    display: block;
    width: 470px;
    height: 100px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    z-index: 44;
  }
  .teacherBg {
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    border-bottom-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .teacherLeft img {
    width: 533px;
    height: 400px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .teacherRight {
    width: 320px;
    height: 400px;
  }

  .teacherItem {
    width: 320px;
    height: 133px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    background: white;
    text-align: center;
    line-height: 133px;
    cursor: pointer;
  }
  .teacherItemActive {
    background: #25ae85;
    font-size: 27px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }

  .teamLeft {
    width: 287px;
    height: 380px;
  }
  .teamRight {
    width: 529px;
    height: 333px;
    margin-left: 56px;
  }

  div /deep/li span {
    font-size: 16px !important;
  }
  .titleTeam {
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    margin-bottom: 34px;
    margin-left: 50px;
  }
  /* ---------------------------------------------- */

  .newBox {
    width: 850px;
    margin: 0 auto;
    margin-top: 50px;
  }

  .leftCourse {
    width: 467px;
    height: 467px;
  }
  .leftCourse img {
    width: 100%;
    height: 100%;
  }
  .teBox {
    width: 850px;
    margin: 0 auto;
  }
  .teseItem {
    width: 267px;
    height: 204px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .teseItem img {
    width: 267px;
    height: 163px;
    transition: transform 0.3s ease;
  }
  .teseName {
    font-size: 17px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    margin-top: 10px;
  }
  .rightCouse {
    width: 329px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
  }

  .courseItem {
    width: 160px;
    height: 197px;
    background: #feca0a;
    border-radius: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .courseItem0 {
    display: none;
  }
  .courseItem5 {
    width: 329px;
    height: 118px;
    border-radius: 20px;
    background: #feca0a;
    cursor: pointer;
  }

  .courseItemImg {
    width: 160px;
    height: 160px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: inline-block;
    transition: transform 0.3s ease;
  }
  .courseItemImg:hover {
    transform: scale(1.2);
  }
  .courseItemImg5 {
    width: 329px;
    height: 81px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: transform 0.3s ease;
  }
  .courseItemImg5:hover {
    transform: scale(1.2);
  }
  .courseText {
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    height: 38px;
    line-height: 38px;
  }

  .zsMain {
    width: 910px;
    margin: 0 auto;
  }

  .zsAgeTitle {
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
  .beforeLine {
    width: 285px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #ffffff;
    display: inline-block;
    position: absolute;
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
  .afterLine {
    width: 285px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #ffffff;
    display: inline-block;
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
  .beforeLine1 {
    width: 285px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #000000;
    display: inline-block;
    position: absolute;
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
  .afterLine1 {
    width: 285px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #000000;
    display: inline-block;
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
  .zsLiuchengTitle {
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
  }
  .ageFanwei {
    font-size: 23px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-top: 60px;
  }
  .lcBox {
    width: 910px;
    margin: 40px auto;
  }

  .yellowBox {
    background: #ffe500;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    font-size: 23px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    display: inline-block;
    height: 68px;
    line-height: 68px;
  }
  .greenBox {
    background: #25ae85;
    border-radius: 20px 20px 20px 20px;
    font-size: 23px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    height: 68px;
    line-height: 68px;
  }

  .yellowLine {
    width: 55px;
    height: 0px;
    opacity: 1;
    border: 4px solid #ffe500;
    display: inline-block;
  }
  .one {
    width: 310px;
  }
  .two {
    width: 260px;
  }

  .three {
    width: 213px;
  }
  .four {
    width: 213px;
  }
  .five {
    width: 410px;
  }
  .six {
    width: 160px;
  }
  .baoming {
    width: 910px;
    margin: 0 auto;
    margin-top: 0px;
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
  }
  .demo-input-suffix {
    width: 290px;
  }
  div /deep/ .el-input {
    position: relative;
    font-size: 14px;
    width: 290px;
  }
  .titleLabel {
    font-size: 17px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    display: inline-block;
    padding-bottom: 20px;
    position: relative;
  }
  .submitBtn {
    width: 427px;
    height: 53px;
    background: #ffe500;
    border-radius: 100px 100px 100px 100px;
    font-size: 26px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    line-height: 53px;
    margin: 60px auto;
    margin-bottom: 60px;
    cursor: pointer;
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 750px) {
  .screen1920 {
    display: none;
  }
  .screen750 {
    display: block;
    overflow-x: hidden;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }

  .main {
    width: 95%;
    margin: 0 auto;
  }
  .leftContent img {
    width: 40px;
    height: 40px;
  }
  .leTopSpan {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    position: absolute;
    top: 0;
    left: 50px;
  }
  .leBtmSpan {
    position: absolute;
    bottom: 0;
    left: 50px;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
  }
  .mainBtm {
    width: 100%;
    height: 400px;
    display: inline-block;
  }
  .mainTop {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .aboutUs {
    width: 100%;
    margin: 0 auto;
  }
  .aboutLeft1 {
    width: 344px;
    height: 240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .leftOneAbout {
    /* background: blue; */
  }
  .leftTwoAbout {
    width: 50%;
    margin-left: 5px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .imgOne1 {
    width: 167px;
    height: 240px;
  }
  .imgRightOne1 {
    width: 167px;
    height: 116px;
  }
  .imgRightTwo1 {
    width: 167px;
    height: 116px;
  }
  .aboutRight {
    width: 344px;
    height: 145px;
    margin: 0 auto;
  }
  .abRiTitle {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    margin-top: 15px;
    margin-bottom: 8px;
  }
  .aboutRight p {
    width: 343px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 24px;
  }
  .development {
    width: 344px;
    margin: 0 auto;
  }
  .developBox {
    overflow: hidden;
  }
  .deveItem {
    width: 69px;
  }
  .lineYellow {
    width: 69px;
    height: 10px;
    background: #ffe500;
    margin-top: 30px;
  }
  .lineGreen {
    width: 69px;
    height: 10px;
    background: #25ae85;
    margin-top: 30px;
  }
  .deveTop {
    width: 69px;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    text-align: center;
    margin-top: 10px;
  }

  .deveBtmText {
    width: 345px;
  }
  .deveBtmText .titleDeve {
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    margin-bottom: 10px;
  }
  .deveBtmText {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    line-height: 22px;
  }
  .devetopImg {
    width: 24px;
    height: 24px;
    margin: 0 22px 0px 22px;
  }

  .teacherBox {
    width: 344px;
    margin: 0 auto;
  }
  .teacherMain {
    width: 100%;
    height: 180px;
    margin-top: 20px;
    display: flex;
  }

  .teacherLeft {
    width: 235px;
    height: 180px;
    position: relative;
  }
  .teacherLeft img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .teacherLeft span {
    position: absolute;
    left: 9px;
    bottom: 25px;
    display: block;
    width: 225px;
    height: 126px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }

  .teacherRight {
    width: 108px;
    height: 180px;
  }

  .teacherItem {
    width: 108px;
    height: 60px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    background: white;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
  }
  .teacherItemActive {
    background: #25ae85;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .teacherItem:first-child {
    border-top-right-radius: 20px;
  }
  .teacherItem:last-child {
    border-bottom-right-radius: 20px;
  }

  .newBox {
    width: 344px;
    margin: 0 auto;
  }
  .newBox .el-carousel,
  .newBox .el-carousel__item {
    width: 100%;
    height: 184px;
  }
  .newBox img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .newsContent {
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffe500;
    display: block;
    display: flex;
    justify-content: space-between;
  }

  .newsLeft {
    width: 60%;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    line-height: 50px;
    margin-left: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .newsRight {
    width: 30%;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    line-height: 50px;
    margin-left: 15px;
  }
  .teamBox {
    width: 344px;
    margin: 0 auto;
  }
  .teamMain {
    width: 100%;
    display: block;
  }
  .teamLeft {
    width: 162px;
    height: 210px;
    margin: 0 auto;
  }
  .teamLeft img {
    width: 100%;
    height: 100%;
  }

  .teamRight {
    width: 158px;
    height: 140px;
    margin: 8px auto;
  }
  .titleTeam {
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    margin-left: 15px;
  }
  .teamRight p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-top: -15px;
  }

  .courseBox {
    width: 344px;
    margin: 0 auto;
  }

  .courseMain {
    width: 100%;
    display: block;
  }

  .leftCourse {
    width: 343px;
    height: 343px;
  }
  .leftCourse img {
    width: 100%;
    height: 100%;
  }
  .teBox {
    width: 344px;
    margin: 0 auto;
  }
  .rightCouse {
    width: 343px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
  }

  .courseItem {
    width: 108px;
    height: 134px;
    background: #feca0a;
    border-radius: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .courseItem0 {
    display: none;
  }
  .courseItem5 {
    width: 225px;
    height: 134px;
    border-radius: 20px;
    background: #feca0a;
    cursor: pointer;
  }

  .courseItemImg {
    width: 108px;
    height: 96px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: inline-block;
    transition: transform 0.3s ease;
  }
  .courseItemImg:hover {
    transform: scale(1.2);
  }
  .courseItemImg5 {
    width: 225px;
    height: 97px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: transform 0.3s ease;
  }
  .courseItemImg5:hover {
    transform: scale(1.2);
  }
  .courseText {
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    height: 38px;
    line-height: 38px;
  }

  /* -------------------------------------- */

  .zsBox {
    width: 100%;
    height: 1100px;
  }
  .zsMain {
    width: 375px;
    margin: 0 auto;
  }
  .zsMain .leftContent {
    width: 90%;
    margin: 0 auto;
  }
  .zsMain1 {
    position: relative;
  }
  .zsAge {
    width: 100%;
    height: 90px;
    padding-top: 24px;
    background: #25ae85;
  }

  .zsAge1 {
    margin-top: 200px;
  }

  .zsLiucheng {
    width: 100%;
    height: 196px;
    padding-top: 40px;
  }
  .zsAgeTitle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
  .zsLiuchengTitle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
  }

  .beforeLine {
    width: 127px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #ffffff;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  .afterLine {
    width: 127px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #ffffff;
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  .beforeLine1 {
    width: 136px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #000000;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  .afterLine1 {
    width: 136px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px dashed #000000;
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  .ageFanwei {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-top: 20px;
  }

  .lcBox {
    width: 375px;
    margin: 40px auto;
  }

  .yellowBox {
    background: #ffe500;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    font-size: 32px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    display: inline-block;
    height: 96px;
    line-height: 96px;
  }
  .greenBox {
    background: #25ae85;
    border-radius: 20px 20px 20px 20px;
    font-size: 32px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    height: 96px;
    line-height: 96px;
  }

  .yellowLine {
    width: 66px;
    height: 0px;
    opacity: 1;
    border: 6px solid #ffe500;
    display: inline-block;
  }
  .one {
    width: 446px;
  }
  .two {
    width: 378px;
  }

  .three {
    width: 300px;
  }
  .four {
    width: 300px;
  }
  .five {
    width: 588px;
  }
  .six {
    width: 236px;
  }

  .baoming {
    width: 80%;
    margin: 0 auto;
    margin-top: 40px;
    display: block;
  }
  .demo-input-suffix,
  .el-select {
    width: 100%;
    margin-bottom: 15px;
  }

  div /deep/.el-input,
  div /deep/.el-select {
    position: relative;
    font-size: 14px;
    width: 100%;
  }
  div /deep/.el-input__inner {
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    border-radius: 100px;
  }
  div /deep/.el-scrollbar {
    overflow: hidden;
    position: relative;
    z-index: 99;
  }

  .titleLabel {
    width: 100%;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    display: inline-block;
    padding-bottom: 20px;
    position: relative;
    text-align: center;
  }
  .titleLabel img {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 185px;
    top: 0px;
  }

  .submitBtn {
    width: 240px;
    height: 48px;
    background: #ffe500;
    border-radius: 100px 100px 100px 100px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    line-height: 48px;
    margin: 60px auto;
    cursor: pointer;
    margin-bottom: 100px;
  }

  .screen750Box {
    width: 95%;
    height: 200px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    /* background: red; */
    margin-top: 20px;
  }
  .leftScreen {
    width: 179px;
    height: 200px;
  }
  .leftOne {
    width: 179px;
    height: 76px;
    background: #ffe500;
    border-radius: 8px 8px 8px 8px;
  }
  .leftOne p {
    width: 122px;
    height: 12px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 22px;
    text-align: center;
    padding: 16px 0;
    margin: 0 auto;
  }
  .leftTwo {
    width: 179px;
    height: 46px;
    background: #25ae85;
    border-radius: 8px 8px 8px 8px;
    text-align: center;
    line-height: 46px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    margin: 8px auto;
  }
  .leftThree {
    width: 179px;
    height: 46px;
    background: #ffe500;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    line-height: 46px;
  }
  .rightScreen {
    width: 152px;
    height: 200px;
  }
  .rightOne {
    width: 152px;
    height: 46px;
    background: #25ae85;
    border-radius: 8px 8px 8px 8px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 46px;
  }
  .rightTwo {
    width: 152px;
    height: 76px;
    background: #ffe500;
    border-radius: 8px 8px 8px 8px;
    margin: 8px auto;
  }
  .rightTwo p {
    width: 112px;
    height: 12px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 22px;
    padding: 16px 0;
    margin: 0 auto;
  }
  .rightThree {
    width: 152px;
    height: 46px;
    background: #25ae85;
    border-radius: 8px 8px 8px 8px;
    line-height: 46px;
    text-align: center;
    color: #ffffff;
    text-align: center;
  }
  .zsAge1 {
    margin-top: 50px;
  }
  .teseMain1 {
    position: relative;
  }
  .teseTitle {
    width: 100%;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    height: 30px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.4);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 184px;
  }

  .teacherBg {
    width: 100%;
    height: 180px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    border-bottom-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }
  .rightContent {
    font-size: 12px;
  }

  div /deep/ .newBox .el-carousel__container,
  div /deep/ .newBox .el-carousel__item,
  div /deep/ .newBox .el-carousel--horizontal {
    height: 184px !important;
  }
  div /deep/ .newBox .el-carousel__container img {
    height: 184px !important;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  /* el-carousel el-carousel--horizontal */
  .newBox .el-carousel,
  .newBox .el-carousel__item,
  .newBox .el-carousel img {
    width: 100%;
    height: 184px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  div /deep/ .teseMain .el-carousel__container {
    height: 184px !important;
  }
  div /deep/ .teseMain .el-carousel__container img {
    height: 100% !important;
  }
  .teseMain .el-carousel,
  .teseMain .el-carousel__item,
  .teseMain .el-carousel img {
    width: 100%;
    height: 184px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  div /deep/ .banner750 .el-carousel__container {
    height: 146px !important;
  }
  div /deep/ .banner750 .el-carousel__container img {
    height: 100% !important;
  }
  .banner750 .el-carousel,
  .banner750 .el-carousel__item,
  .banner750 .el-carousel img {
    width: 100%;
    height: 146px !important;
  }
}
</style>
