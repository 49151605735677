import axios from 'axios'

function getRequest(url, data) {
    return new Promise(function (resolve, reject) {
        axios.get(url, data).then(function (res) {
            resolve(res)
        }, function (err) {
            reject(err)
        })
    })
}

function postRequest(url, data) {
    return new Promise(function (resolve, reject) {
        axios.post(url, data).then(function (res) {
            resolve(res)
        }, function (err) {
            reject(err)
        })
    })
}

function putRequest(url, data) {
    return new Promise(function (resolve, reject) {
        axios.put(url, data).then(function (res) {
            resolve(res)
        }, function (err) {
            reject(err)
        })
    })
}

// axios.defaults.baseURL = ''
// 开发
axios.defaults.baseURL = '/app'
export default {

    // 所有页面
    allPage(data) {
        return postRequest('yy/records', data)
    },
    // 一级导航
    firstNav(data) {
        return getRequest('yy/navigation/firstNavigationList', {
            params: data
        })
    },
    // 二级导航
    secondNav(data) {
        return getRequest('yy/navigation/secondaryNavigationList', {
            params: data
        })
    },
    // banner
    banner(data) {
        return getRequest('yy/banner/webBannerList', {
            params: data
        })
    },
    // 首页所有内容
    home(data) {
        return getRequest('yy/home/homeList', {
            params: data
        })
    },
    // 发展历程
    fazhan(data) {
        return getRequest('yy/history/webHistoryList', {
            params: data
        })
    },
    // 提交信息
    sumitHome(data) {
        return postRequest('yy/reservation/addReservation', data)
    },
    // 师资团队
    teacherNewList(data) {
        return getRequest('/yy/teacher/newList', {
            params: data
        })
    },
    // 新闻
    xinwen(data) {
        // yy/news//newsList
        return getRequest('/yy/news/newsList', {
            params: data
        })
    },
    // 新闻详情
    xwDetail(data) {
        return getRequest('/yy/news/' + data, {

        })

    },
    // 活动展示
    hdFangfa(data) {
        return getRequest('/yy/activity/newsList', {
            params: data
        })
    },
    // 活动展示所有标题
    allBiaoti(data) {
        return getRequest('/yy/activity/titleList', {
            params: data
        })
    },
    // 活动详情接口
    hdDetail(data) {
        return getRequest('/yy/activity/' + data, {

        })
    },
    // 获取验证码接口
    captchaImage(data) {
        return getRequest('/captchaImage', {

        })
    },
    // 获取用户消息
    getInfo(data) {
        return getRequest('/getInfo', {
            params: data
        })
    },
    // h5页面客户列表 ----------------------------------------------------------------------------------------------
    list(data) {
        return getRequest('/yy/fy/h5List', {
            params: data
        })
    },
    // 渠道
    listSource(data) {
        return getRequest('/yy/source/list', {
            params: data
        })
    },
    // 新增预约
    addReservation(data) {
        return postRequest('/yy/reservation', data)
    },
    // 修改预约
    updateReservation(data) {
        return putRequest('/yy/reservation', data)

    },
    // 登录接口
    h5login(data) {
        return postRequest('/login', data)
    },

    addJob(data) {
        return postRequest('/yy/job', data)
    }
}