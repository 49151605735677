import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 全局引入封装好的api
import api from './API/api'
Vue.prototype.api = api
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueQuillEditor from 'vue-quill-editor'
// 引入相关css
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor);


//main.js 引入    多张图片放大插件 多功能
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
Viewer.setDefaults({
  zIndex: 9999999,
  'inline': false, //启用inline模式
  'button': true, //显示右上角关闭按钮
  'navbar': true, //显示缩略图导航
  'title': false, //显示当前图片的标题
  'toolbar': true, //显示工具栏
  'tooltip': false, //显示缩略百分比
  'movable': false, //图片是否可移动
  'zoomable': true, //图片是否可缩放
  'rotatable': true, //图片是否可旋转
  'scalable': true, //图片是否可反转
  'transition': true, //使用css3过度
  'fullscreen': true, //播放时是否全屏
  'keyboard': false, //
})




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')