<template>
  <div id="app">
    <div id="nav">
      <div class="screen1920">
        <div class="xuanfu" v-on:mouseenter="showBig" v-if="show">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/xufuLogo.png"
            alt=""
          />
        </div>
        <div class="xfKunag" v-on:mouseleave="showSmall" v-if="!show">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/xufuLogo.png"
            alt=""
            class="xuanfuLogo"
          />

          <div class="kuangBox">
            <div class="yuyue" @click="toCanguan">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/yuyuecanguan.png"
                alt=""
              />
              <div class="yyTitle">预约参观</div>
            </div>
         
            <div
              class="yuyue"
              v-on:mouseenter="showLianxi"
              v-on:mouseleave="closeLianxi"
            >
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/jiazhangrexcian.png"
                alt=""
              />
              <div class="yyTitle">家长热线</div>
            </div>  
            <div
              class="yuyue"
              v-on:mouseenter="showGuanzhu"
              v-on:mouseleave="closeGuanzhu"
            >
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/lianxiwomenewm.png"
                alt=""
              />
              <div class="yyTitle">关注我们</div>
            </div>
          </div>
        </div>
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/phoneBj.png"
          alt=""
          class="jiazhangrexian"
          v-if="!lianxi"
        />
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/ewmBj.png"
          alt=""
          class="guanzhuwomen"
          v-if="!guanzhu"
        />
      </div>
      <router-link to="/"></router-link>
    </div>
    <div id="content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      lianxi: true,
      guanzhu: true,
    };
  },

  methods: {
    showBig() {
      this.show = false;
    },
    showSmall() {
      this.show = true;
    },
    showLianxi() {
      this.lianxi = false;
    },
    closeLianxi() {
      this.lianxi = true;
    },
    showGuanzhu() {
      this.guanzhu = false;
    },
    closeGuanzhu() {
      this.guanzhu = true;
    },
    toCanguan() {
      localStorage.setItem("yyNum", 0);
      this.$router.push({ path: "/" });
      if (this.$store.state.count == 0) {
        this.$store.commit("increment");
      } else {
        this.$store.commit("subtraction");
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
}
.screen1920 {
  display: block;
}
.xuanfu {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 999;
  cursor: pointer;
}
.xuanfu img,
.xuanfuLogo {
  width: 122px;
  height: 122px;
}
.xfKunag {
  width: 122px;
  height: 460px;
  border-radius: 20px;
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 999;
  cursor: pointer;
}
.jiazhangrexian {
  width: 167px;
  height: 57px;
  position: fixed;
  right: 172px;
  bottom: 280px;
  z-index: 999;
}
.guanzhuwomen {
  width: 120px;
  height: 120px;
  position: fixed;
  right: 172px;
  bottom: 140px;
  z-index: 999;
}
.kuangBox {
  width: 100%;
  height: 338px;
  background: #ffe500;
  margin-top: -30px;
  border-radius: 20px 20px 20px 20px;
}
.yuyue {
  width: 72px;
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  padding-top: 30px;
  margin: 0 auto;
}
.yuyue img {
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 1366px) {
  .xuanfu {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    position: fixed;
    bottom: 20px;
    right: 50px;
    z-index: 999;
    cursor: pointer;
  }
  .xuanfu img,
  .xuanfuLogo {
    width: 122px;
    height: 122px;
  }
  .xfKunag {
    width: 122px;
    height: 460px;
    border-radius: 20px;
    position: fixed;
    bottom: 20px;
    right: 50px;
    z-index: 999;
    cursor: pointer;
  }
  .jiazhangrexian {
    width: 167px;
    height: 57px;
    position: fixed;
    right: 172px;
    bottom: 180px;
    z-index: 999;
  }
  .guanzhuwomen {
    width: 120px;
    height: 120px;
    position: fixed;
    right: 172px;
    bottom: 50px;
    z-index: 999;
  }
  .kuangBox {
    width: 100%;
    height: 338px;
    background: #ffe500;
    margin-top: -30px;
    border-radius: 20px 20px 20px 20px;
  }
  .yuyue {
    width: 72px;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    padding-top: 30px;
    margin: 0 auto;
  }
  .yuyue img {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 750px) {
  .screen1920 {
    display: none;
  }
}
</style>
