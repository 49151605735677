<template>
  <div class="footerBox">
    <div class="screen1920">
      <div class="footerMain">
        <div class="youlian">
          <div class="ylTop">
            <div class="ylLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
                alt=""
              />
            </div>
            <div class="ylRight">
              <div class="ylRtop">北京朝阳燕京雍阳幼儿园</div>
              <div class="ylRbtm">
                Beijing Chaoyang Yanjing Yongyang Kindergarten
              </div>
            </div>
          </div>
          <div class="lianxi">联系方式：010-53381073</div>
          <div class="dizhi">
            所在地址：北京市朝阳区常意路4号院3号楼常楹公元小区
          </div>
        </div>
        <div class="gzh">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/erweima.png"
            alt=""
            class="gzhImg"
          />

          <div class="gzhTitle">官方微信公众号</div>
        </div>
      </div>
      <div class="footerBtm">
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo1.png"
          class="hbImg"
          alt=""
        />
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo2.png"
          class="hbImg"
          alt=""
        />
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo3.png"
          class="hbImg"
          alt=""
        />
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo4.png"
          class="hbImg"
          alt=""
        />
        <!-- <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo5.png"
          class="hbImg"
          alt=""
        /> -->
        <!-- <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo6.png"
          class="hbImg"
          alt=""
        /> -->
      </div>

      <div class="beian">
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          class="routerColor"
          >京ICP备2023030354号-1</a
        >
      </div>
    </div>
    <div class="screen750">
      <div class="footerMian">
        <div class="top1">
          <div class="leftT1">
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/smallLogo.png"
              alt=""
            />
          </div>
          <div class="rightT1">
            <div class="rightTt1">北京朝阳燕京雍阳幼儿园</div>
            <div class="rightTt2">
              Beijing Chaoyang Yanjing Yongyang Kindergarten
            </div>
          </div>
        </div>
        <div class="erweima750">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/erweima.png"
            alt=""
          />
        </div>
        <div class="zi">官方公众号</div>
        <div class="lianxifansghi">联系方式：010-53381073</div>
        <div class="dizhi1">
          所在地址：北京市朝阳区常意路4号院3号楼常楹公元小区
        </div>

        <div class="hezuoBox">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo1.png"
            alt=""
          />
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo2.png"
            alt=""
          />

          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo4.png"
            alt=""
          />
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo3.png"
            alt=""
          />
          <!-- <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo5.png"
            alt=""
          /> -->
          <!-- <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/hezuo6.png"
            alt=""
          /> -->
        </div>
        <div class="beian">
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            class="routerColor"
            >京ICP备2023030354号-1</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerBox",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.screen1920 {
  display: block;
}
.screen750 {
  display: none;
}
.footerBox {
  width: 100%;
  height: 625px;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/bottom.png");
  background-size: 100% 100%;
}

.footerMain {
  width: 980px;
  padding-top: 200px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.hezuo {
  width: 208px;
}

.youlian {
  width: 504px;
  height: 300px;
}
.gzh {
  width: 196px;
}

.gzhTitle {
  font-size: 28px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
}
.footerBtm {
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}
.hbImg {
  display: inline-block;
  height: 50px;
}
.hbImg:first-child {
  width: 244px;
}
.hbImg:nth-child(2) {
  width: 214px;
}
.hbImg:nth-child(3) {
  width: 300px;
}
.hbImg:nth-child(4) {
  width: 172px;
}
/* .hbImg:nth-child(5) {
  width: 148px;
} */
.hbImg:nth-child(5) {
  width: 69px;
}

.ylTop {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.ylLeft {
  width: 80px;
  height: 80px;
}
.ylLeft img {
  width: 100%;
  height: 100%;
}
.ylRight {
  width: 385px;
  height: 80px;
}

.ylRtop {
  font-size: 32px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #252525;
}

.ylRbtm {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
}

.lianxi {
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  margin: 50px auto;
  text-align: center;
}

.dizhi {
  width: 100%;
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  margin-top: 40px;
  margin-bottom: 60px;
}

.beian {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  text-align: center;
  margin-top: 40px;
}
.beian a {
  text-decoration: none;
  color: #252525;
}

.gzhImg {
  width: 140px;
  height: 140px;
  margin: 0 auto;
  display: block;
  margin-bottom: 40px;
}
@media screen and (max-width: 1366px) {
  .footerBox {
    width: 100%;
    height: 445px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/bottom.png");
    background-size: 100% 100%;
  }

  .footerMain {
    width: 910px;
    padding-top: 126px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    /* background: red; */
  }

  .hezuo {
    width: 208px;
  }

  .youlian {
    width: 369px;
    height: 151px;
  }
  .gzh {
    width: 142px;
  }

  .gzhTitle {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
  }
  .footerBtm {
    width: 910px;
    height: 36px;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
  }
  .hbImg {
    display: inline-block;
    height: 36px;
  }
  .hbImg:first-child {
    width: 174px;
  }
  .hbImg:nth-child(2) {
    width: 152px;
  }
  .hbImg:nth-child(3) {
    width: 213px;
  }
  .hbImg:nth-child(4) {
    width: 122px;
  }
  /* .hbImg:nth-child(5) {
  width: 148px;
} */
  .hbImg:nth-child(5) {
    width: 49px;
  }

  .ylTop {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .ylLeft {
    width: 80px;
    height: 80px;
  }
  .ylLeft img {
    width: 100%;
    height: 100%;
  }
  .ylRight {
    width: 274px;
    height: 80px;
  }

  .ylRtop {
    font-size: 23px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #252525;
    margin: 10px 0;
  }

  .ylRbtm {
    font-size: 11px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
  }

  .lianxi {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    margin: 10px auto;
    text-align: center;
  }

  .dizhi {
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .beian {
    font-size: 11px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    margin-top: 40px;
  }
  .beian a {
    text-decoration: none;
    color: #252525;
  }

  .gzhImg {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    display: block;
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 750px) {
  .screen1920 {
    display: none;
  }
  .screen750 {
    display: block;
  }

  /* .footerMian {
    width: 100%;
    height: 551px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/phoneBottom.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  } */
  .footerBox {
    width: 100%;
    height: 621px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/phoneBottom.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .top1 {
    width: 100%;
    height: 60px;
    padding-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 26px;
  }
  .leftT1 {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  .leftT1 img {
    width: 100%;
    height: 100%;
  }
  .rightT1 {
    /* width: 220px; */
  }
  .rightTt1 {
    font-size: 20px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #252525;
  }
  .rightTt2 {
    font-size: 10px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
  }
  .erweima750 {
    width: 200px;
    height: 220px;
    margin: 0 auto;
  }
  .erweima750 img {
    width: 200px;
    height: 200px;
  }
  .zi {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
  }
  .lianxifansghi {
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 12px;
  }
  .dizhi1 {
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    text-align: center;
    margin-bottom: 16px;
  }
  .hezuoBox {
    width: 95%;
    height: 70px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .hezuoBox img:nth-child(1) {
    width: 166px;
    height: 34px;
    margin-right: 15px;
  }
  .hezuoBox img:nth-child(2) {
    width: 155px;
    height: 34px;
    margin-right: 0px;
  }
  .hezuoBox img:nth-child(3) {
    width: 81px;
    height: 20px;
    margin-top: 10px;
    margin-right: 15px;
  }
  .hezuoBox img:nth-child(4) {
    width: 120px;
    height: 20px;
    margin-top: 10px;
    margin-right: 15px;
  }
  /* .hezuoBox img:nth-child(5) {
    width: 68px;
    height: 20px;
    margin-top: 10px;
  } */
  .hezuoBox img:nth-child(5) {
    width: 36px;
    height: 20px;
    margin-top: 10px;
    margin-right: 15px;
  }
}
</style>
