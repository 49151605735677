<template>
  <div class="nav">
    <div class="topBox pcNav">
      <div class="leftImg" @click="toHome">
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/logo.png"
          alt=""
        />
      </div>

      <div class="rightNav">
        <div
          :class="[
            title != '' && active == index
              ? 'navItemActive navItem'
              : 'navItem',
          ]"
          v-for="(item, index) in firstList"
          :key="index"
          v-on:mouseenter="getTitle(item.title, index)"
          v-on:mouseleave="resetTitle"
          @click="toSkip(item.title)"
        >
          {{ item.title }}
          <div
            class="gyyyBox xwzxBox"
            v-if="title == '课程活动' && active == index"
          >
            <div
              class="ggyyItem"
              v-for="(item, index) in kchd"
              :key="index"
              @click="toSkip1(item.title)"
            >
              {{ item.title }}
            </div>
          </div>
          <div
            class="gyyyBox"
            v-if="title == '关于燕京雍阳' && active == index"
          >
            <div
              class="ggyyItem"
              v-for="(item, index) in gyyy"
              :key="index"
              @click="toSkip1(item.title)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <nav class="navHomeLine">
        <div class="leftLogo">
          <!-- <router-link to="/"> -->
          <div class="logo" @click="toHome">
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/logo.png"
              alt=""
            />
          </div>
          <!-- </router-link> -->
        </div>
        <div class="rightLogo">
          <div class="navImg">
            <img
              src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/nav/mobileLogo.png"
              alt=""
              @click="mobileNavClick"
            />
          </div>
        </div>
      </nav>

      <div class="daohangNav" v-if="mobileNavShow" @touchmove.prevent>
        <el-menu
          default-active="0"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="navClose"
          background-color="white"
          active-text-color="#ffd04b"
        >
          <!-- &&item.title!='课程活动' -->
          <div v-for="(item, index) in firstList" :key="index">
            <el-menu-item
              :index="index"
              v-if="item.title != '关于燕京雍阳' && item.title != '课程活动'"
              @click="toSkip(item.title)"
            >
              <span slot="title" class="mobileNavTitle">
                {{ item.title }}
              </span>
            </el-menu-item>
            <!-- :index="index" -->
            <el-submenu v-if="item.title == '关于燕京雍阳'">
              <template slot="title">
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="index - gIndex"
                  @click="toSkip1(itemOne.title)"
                  class="myList"
                  v-for="(itemOne, gIndex) in gyyy"
                  :key="gIndex"
                >
                  {{ itemOne.title }}
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu :index="index" v-if="item.title == '课程活动'">
              <template slot="title">
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="index - kIndex"
                  @click="toSkip1(itemTwo.title)"
                  class="myList"
                  v-for="(itemTwo, kIndex) in kchd"
                  :key="kIndex"
                >
                  {{ itemTwo.title }}
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </div>
        </el-menu>
      </div>
      <div
        class="mask"
        v-if="mobileNavShow"
        @click="mask"
        @touchmove.prevent
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navHeader",
  components: {},
  data() {
    return {
      firstList: [],
      gyyy: [],
      xwzx: [],
      kchd: [],
      title: "",
      active: "",
      // 移动端导航是否显示
      mobileNavShow: false,
    };
  },
  mounted() {
    // 一级菜单
    this.api.firstNav({}).then((res) => {
      this.firstList = res.data;
    });
    // 关于燕京雍阳二级菜单
    this.api.secondNav({ id: 2 }).then((res) => {
      this.gyyy = res.data;
    });

    // 课程活动二级菜单
    this.api.secondNav({ id: 7 }).then((res) => {
      this.kchd = res.data;
    });
  },
  methods: {
    // 移动端点击
    mobileNavClick() {
      if (this.mobileNavShow == true) {
        this.mobileNavShow = false;
      } else {
        this.mobileNavShow = true;
      }
    },
    mask() {
      this.mobileNavShow = false;
    },
    // 导航点击事件
    handleOpen(key, keyPath) {},
    navClose(key, keyPath) {},

    toHome() {
      this.$router.push({ path: "/" });
    },
    getTitle(e, index) {
      this.title = e;
      this.active = index;
    },

    resetTitle() {
      this.title = "";
    },

    toSkip(e) {
      // alert(e)
      console.log(e);
      if (e == "首页") {
        this.$router.push({ path: "/" });
      } else if (e == "联系我们") {
        this.$router.push({ path: "/lianxi" });
      } else if (e == "家长服务") {
        this.$router.push({ path: "/jiazhang" });
      } else if (e == "园区介绍") {
        this.$router.push({ path: "/yuanqu" });
      } else if (e == "新闻中心") {
        this.$router.push({ path: "/xinwen" });
      }
    },
    toSkip1(e) {
      if (e == "关于我们") {
        this.$router.push({ path: "/about" });
      } else if (e == "教学理念") {
        this.$router.push({ path: "/jiaoxue" });
      } else if (e == "师资团队") {
        this.$router.push({ path: "/shizi" });
      } else if (e == "课程介绍") {
        this.$router.push({ path: "/kecheng" });
      } else if (e == "活动展示") {
        this.$router.push({ path: "/huodong" });
      }
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  height: 90px;
  background: #ffe500;
}
.topBox {
  width: 1800px;
  height: 90px;
  margin: 0 auto;
}
.leftImg {
  width: 411px;
  height: 60px;
  padding-top: 15px;
  float: left;
  cursor: pointer;
}
.leftImg img {
  width: 100%;
  height: 100%;
}
.rightNav {
  width: 1100px;
  height: 90px;
  float: left;
  margin-left: 280px;
  position: relative;
  z-index: 9999;
}

.navItem {
  width: 144px;
  height: 90px;
  float: left;
  text-align: center;
  line-height: 90px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}
.navItemActive {
  background: #25ae85;
  color: white;
}

.gyyyBox {
  width: 144px;
  height: 150px;
  background: #25ae85;
}
.xwzxBox {
  left: 288px;
  height: 100px;
}
.kchdBox {
  left: 432px;
  height: 100px;
}
.ggyyItem {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  color: white;
}
.ggyyItem:hover {
  background: #ffe500;
  color: black;
}
.pcNav {
  display: block;
}
.mobile {
  display: none;
}
@media screen and (max-width: 1680px) {
  .topBox {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .leftImg {
    width: 360px;
    height: 53px;
    padding-top: 15px;
    float: left;
    cursor: pointer;
  }
  .rightNav {
    width: 60%;
    float: right;
    margin-left: 100px;
  }
  .navItem {
    width: 140px;
    font-size: 18px;
  }
  .gyyyBox {
    width: 140px;
  }
}
@media (min-width: 1366px) and (max-width: 1440px) {
  .topBox {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .leftImg {
    width: 25%;
    height: 50px;
    padding-top: 0;
  }
  .rightNav {
    width: 60%;
    float: right;
    margin-left: 0;
  }
  .navItem {
    width: 120px;
    font-size: 16px;
  }
  .gyyyBox {
    width: 120px;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .nav {
    height: 64px;
  }
  .navItem,
  .rightNav,
  .topBox {
    height: 64px;
    line-height: 64px;
  }
  .navItem {
    width: 100px;
    font-size: 14px;
  }
  .leftImg {
    width: 292px;
    height: 43px;
  }
  .gyyyBox {
    width: 100px;
  }
  .rightNav {
    width: 700px;
  }
}

@media (min-width: 1080px) and (max-width: 1280px) {
  .topBox {
    width: 1280px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .leftImg {
    width: 274px;
    height: 40px;
    padding-top: 0;
  }
  .rightNav {
    width: auto;
  }
  .nav {
    height: 64px;
  }
  .navItem,
  .rightNav,
  .topBox {
    height: 64px;
    line-height: 64px;
  }
  .navItem {
    width: 90px;
    font-size: 12px;
  }
  .gyyyBox {
    width: 90px;
  }
  .rightNav {
    width: 640px;
  }
}

@media screen and (max-width: 750px) {
  .pcNav {
    display: none;
  }
  .mobile {
    display: block;
  }
  .nav {
    height: 60px;
  }
  .navHomeLine {
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .logo {
    width: 207px;
    height: 32px;
  }
  .logo img {
    width: 100%;
    height: 100%;
  }
  .rightLogo img {
    width: 28px;
    height: 28px;
  }
  .daohangNav {
    position: absolute;
    z-index: 3000;
    width: 40%;
    right: 0;
    top: 59px;
  }
  .daohangNav ul li {
    background-color: #ffe501 !important;
    font-weight: bold;
  }
  .daohangNav /deep/ .el-submenu__title {
    background-color: #ffe501 !important;
  }
  .daohangNav /deep/ .el-menu-item-group ul li {
    background-color: #25ae85 !important;
    color: white;
    font-weight: bold;
  }
  .mask {
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 1851;
  }
  div /deep/ .el-submenu__title i {
    color: black;
    font-weight: bold;
  }
}
</style>
